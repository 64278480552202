import React from 'react';
import { List, ListItem, ListItemText, IconButton } from '@mui/material'; // Updated package name
import DeleteIcon from '@mui/icons-material/Delete'; // Updated package name

const CameraFeedList = ({ feeds, deleteFeed }) => {
  return (
    <List>
      {feeds.map((feed, index) => (
        <ListItem key={feed.id}>
          <ListItemText primary={feed.url} />
          <IconButton edge="end" aria-label="delete" onClick={() => deleteFeed(index)}>
            <DeleteIcon />
          </IconButton>
        </ListItem>
      ))}
    </List>
  );
};

export default CameraFeedList;
