import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system'; // Updated styling package
import config from '../config';

const FeedContainer = styled('div')({
  marginBottom: (theme) => theme.spacing(4),
});

const FeedTitle = styled('h3')({
  marginBottom: (theme) => theme.spacing(2),
  color: '#3f51b5',
});

const Image = styled('img')({
  width: '100%',
  height: 'auto',
  borderRadius: (theme) => theme.shape.borderRadius,
  boxShadow: (theme) => theme.shadows[3],
});

const CameraFeed = ({ feed, onSaveFeed }) => {
  const [loading, setLoading] = useState(true); // Add loading state
  const [, setError] = useState(false);

  useEffect(() => {
    const loadImage = async () => {
      try {
        const image = new Image();
        image.onload = () => {
          console.log(`Image loaded successfully for feed: ${feed}`);
          setLoading(false);
          setError(false);
          onSaveFeed(feed); // Save feed when loaded successfully
        };
        image.onerror = () => {
          console.error(`Error loading image for feed: ${feed}`);
          setLoading(false);
          setError(true);
        };
        image.src = `${config.baseUrl}/detect?video_url=${encodeURIComponent(feed)}`;
      } catch (error) {
        console.error('Error creating image:', error);
        setLoading(false);
        setError(true);
      }
    };

    loadImage();
  }, [feed, onSaveFeed]);

  return (
    <FeedContainer>
      <FeedTitle>Feed: {feed}</FeedTitle>
      {loading ? (
        <p style={{ color: '#3f51b5' }}>Loading feed...</p>
      ) : (
        <Image
          src={`${config.baseUrl}/detect?video_url=${encodeURIComponent(feed)}`}
          alt={`Camera ${feed} Feed`}
        />
      )}
    </FeedContainer>
  );
};

export default CameraFeed;
