import React, { useState, useEffect } from 'react';
import { Container, Typography, Grid, Paper, CircularProgress, Snackbar } from '@mui/material';
import { styled } from '@mui/system';
import CameraFeedList from './components/CameraFeedList';
import CameraFeedForm from './components/CameraFeedForm';
import CameraFeed from './components/CameraFeed';
import axios from 'axios';
import config from './config';

const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  height: '100%',
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

const App = () => {
  const [feeds, setFeeds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchFeeds = async () => {
      try {
        const response = await axios.get(`${config.DBUrl}/feeds`);
        setFeeds(response.data.feeds);
      } catch (error) {
        setError('Failed to fetch feeds');
      } finally {
        setLoading(false);
      }
    };

    fetchFeeds();
  }, []);

  const addFeed = async (feed) => {
    try {
      const response = await axios.post(`${config.DBUrl}/feeds`, { url: feed });
      setFeeds([...feeds, response.data]);
    } catch (error) {
      setError('Failed to add feed');
    }
  };

  const deleteFeed = async (index) => {
    const feedToDelete = feeds[index];
    try {
      await axios.delete(`${config.DBUrl}/feeds/${feedToDelete.id}`);
      setFeeds(feeds.filter((_, i) => i !== index));
    } catch (error) {
      setError('Failed to delete feed');
    }
  };

  const setFeedLoaded = (feed) => {
    // Implement your logic for handling loaded feeds here
  };

  const handleCloseError = () => {
    setError('');
  };

  const checkFeedExists = async (url) => {
    // Implement your logic to check if the URL already exists
    // For example, you can perform an API request to your backend
    // and check if the URL exists in your database
    try {
      const response = await fetch(`${config.DBUrl}/checkFeed?url=${encodeURIComponent(url)}`);
      const data = await response.json();
      return data.exists; // Assuming your backend responds with { exists: true/false }
    } catch (error) {
      console.error('Error checking if feed exists:', error);
      return false; // Return false in case of any errors
    }
  };

  return (
    <StyledContainer>
      <StyledTypography variant="h2" gutterBottom>
        Violence Detection System
      </StyledTypography>
      <StyledGrid container spacing={3}>
        <Grid item xs={12} md={4}>
          <StyledPaper>
            <CameraFeedForm addFeed={addFeed} checkFeedExists={checkFeedExists} />
            <CameraFeedList feeds={feeds} deleteFeed={deleteFeed} />
          </StyledPaper>
        </Grid>
        <Grid item xs={12} md={8}>
          {loading ? (
            <CircularProgress />
          ) : (
            feeds.map((feed, index) => (
              <CameraFeed
                key={feed.id}
                feed={feed.url}
                loading={false} // Adjust loading state according to your logic
                setFeedLoaded={setFeedLoaded}
                onSaveFeed={() => {}} // No need to handle save in the front-end for now
              />
            ))
          )}
        </Grid>
      </StyledGrid>
      <Snackbar open={!!error} autoHideDuration={6000} onClose={handleCloseError} message={error} />
    </StyledContainer>
  );
};

export default App;
