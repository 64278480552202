import React, { useState } from 'react';
import { TextField, Button, Grid } from '@mui/material'; // Updated package name

const CameraFeedForm = ({ addFeed, checkFeedExists }) => {
  const [feedUrl, setFeedUrl] = useState('');
  const [error, setError] = useState('');
  const [existsError, setExistsError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if the URL already exists in the database
    const exists = await checkFeedExists(feedUrl);
    
    if (exists) {
      setExistsError('URL already exists in the database.');
      return;
    }

    if (isValidURL(feedUrl)) {
      addFeed(feedUrl);
      setFeedUrl('');
      setError('');
      setExistsError('');
    } else {
      setError('Please enter a valid URL.');
    }
  };

  const isValidURL = (url) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <TextField
            label="Feed URL"
            variant="outlined"
            value={feedUrl}
            onChange={(e) => setFeedUrl(e.target.value)}
            fullWidth
            required
            error={!!error || !!existsError}
            helperText={error || existsError}
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Add Feed
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default CameraFeedForm;
